<template>
  <div id="week-days">
    <ul>
      <li
        v-for="(weekDay, dayIndex) in weekDays"
        :key="dayIndex"
        class="week-days"
      >
        <div class="week-day">
          <vs-checkbox
            v-model="bot.availability.weekDays"
            :vs-value="weekDay.value"
            class="checkbox"
          >
            {{
              lang.botMaker.addBot.botAvailability.weekDaysOptions[dayIndex][
                languageSelected
              ]
            }}
          </vs-checkbox>
          <HoursAvailableByDay
            v-if="bot.availability.weekDays.includes(dayIndex)"
            :day="dayIndex"
          />
        </div>
      </li>
    </ul>

    <kona-alert v-show="validations.availability.weekDaysEmpty">
      {{
        lang.botMaker.addBot.botAvailability.daysAvailabilityError.noSelection[
          languageSelected
        ]
      }}
    </kona-alert>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'WeekDaysHoursAvailable',
  components: {
    HoursAvailableByDay: () => import('./HoursAvailableByDay.vue')
  },
  data() {
    return {
      weekDays: []
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('bots', ['bot', 'validations'])
  },
  watch: {
    languageSelected() {
      this.initWeekDays()
    }
  },
  methods: {
    initWeekDays() {
      this.weekDays = [
        {
          text: this.lang.botMaker.addBot.botAvailability.weekDaysAbbr.sunday[
            this.languageSelected
          ],
          value: 0
        },
        {
          text: this.lang.botMaker.addBot.botAvailability.weekDaysAbbr.monday[
            this.languageSelected
          ],
          value: 1
        },
        {
          text: this.lang.botMaker.addBot.botAvailability.weekDaysAbbr.tuesday[
            this.languageSelected
          ],
          value: 2
        },
        {
          text: this.lang.botMaker.addBot.botAvailability.weekDaysAbbr
            .wednesday[this.languageSelected],
          value: 3
        },
        {
          text: this.lang.botMaker.addBot.botAvailability.weekDaysAbbr.thursday[
            this.languageSelected
          ],
          value: 4
        },
        {
          text: this.lang.botMaker.addBot.botAvailability.weekDaysAbbr.friday[
            this.languageSelected
          ],
          value: 5
        },
        {
          text: this.lang.botMaker.addBot.botAvailability.weekDaysAbbr.saturday[
            this.languageSelected
          ],
          value: 6
        }
      ]
    }
  },
  mounted() {
    this.initWeekDays()
  }
}
</script>

<style lang="scss" scoped>
.week-days {
  padding-bottom: 5px;
  .week-day {
    display: flex;
    align-items: center;
    min-height: 43px;
    .checkbox {
      min-width: 140px;
    }
  }
}
</style>
